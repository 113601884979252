import React from 'react'
import InputValidator from '../../common/InputValidator'

interface OwnProps {
  readonly email: string
  readonly updateNewMembersEmailList: (index: number, newEmail?: string) => void
  readonly index: number
  readonly removeNewMemberEmailInput: (index: number) => void
  readonly placeholderText?: string
  readonly required?: boolean
}

const FriendInviteMemberAccess: React.FunctionComponent<OwnProps> = ({
  email,
  updateNewMembersEmailList,
  index,
  removeNewMemberEmailInput,
  placeholderText,
  required = true,
}) => {
  const validators = ['isEmail']
  const errorMessages = ['Email is not valid']
  const makeRequired = required && index === 0 

  if (makeRequired) {
      validators.push('required')
      errorMessages.push('This field is required')
  }

  return (
    <>
      <div className='invite-email-row'>
        <InputValidator
          className='form-control full-width'
          name='email'
          value={email.replace(/\s/g, '')}
          onChange={event => updateNewMembersEmailList(index, event.target.value)}
          placeholder={placeholderText || 'Type email'}
          autoComplete='new-password'
          validators={validators}
          errorMessages={errorMessages}
        />
        {index > 2 && (
          <button
            type='button'
            className='button-blank button-no-padding'
            onClick={() => removeNewMemberEmailInput(index)}
          >
            <i className='fal fa-trash-alt' aria-hidden='true' />
          </button>
        )}
      </div>
    </>
  )
}

export default FriendInviteMemberAccess
