import React, { Component } from 'react'
import Modal from 'react-modal'

export default class ConfirmModal extends Component {
  state = { modalIsOpen: this.props.modalIsOpen }
  afterOpenModal() {}

  closeModal = () => {
    if (this.props.closeModal) {
      this.props.closeModal()
    } else {
      this.setState({ modalIsOpen: false })
    }
    if (this.props.afterClose) {
      this.props.afterClose()
    }
  }

  handleYes = () => {
    this.props.confirmedAction(this.props.closeModal)
    if (!this.props.preventCloseModal) {
      this.props.closeModal()
    }
  }

  handleDismiss = () => {
    if (this.props.dismissAction) {
      this.props.dismissAction()
    }

    this.props.closeModal()
  }

  static defaultProps = {
    yesButtonClass: 'button-warning',
  }

  render() {
    const {
      message,
      closeModal,
      yesButtonClass,
      title,
      dismissButtonLabel,
      okButtonLabel,
      extraClassName,
      isLoading,
      hideOkButton,
    } = this.props
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={closeModal}
        className={`react-modal confirm-modal ${extraClassName}`}
        overlayClassName='react-modal-overlay'
        contentLabel='Example Modal'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <button type='button' onClick={this.closeModal} className='close' aria-label='Close'>
              <span aria-hidden='true' className='icon-close'></span>
            </button>
            {title ? title : 'Are you sure?'}
          </div>
          <div className='modal-body'>
            {typeof message === 'string' ? <p>{message}</p> : message}
          </div>
          <div className='modal-footer'>
            <div className='wrapper'>
              <button
                className='button button-md button-outline cancel-button'
                onClick={this.handleDismiss}
              >
                {dismissButtonLabel ? dismissButtonLabel : 'No'}
              </button>
              {!hideOkButton && (
                <button
                  className={`confirm-button button button-md ${yesButtonClass}`}
                  onClick={this.handleYes}
                  disabled={isLoading}
                >
                  {isLoading && (
                    <>
                      <i className='fas fa-spinner-third fa-spin i-left' />{' '}
                    </>
                  )}
                  {okButtonLabel ? okButtonLabel : 'Yes'}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
