import React from 'react';
import DesignService from '../../api/Designs/Service';
import { ValidatorForm } from 'react-form-validator-core';
import { removeArrayElement } from '../../helpers/Collections';
import { isEmpty } from '../../helpers/StringHelper';
import FriendInviteMemberAccess from '../../components/users/my_companies/FriendInviteMembersAccess';
import I18n from '../../helpers/I18n';
import { Design } from '../../api/Designs/Types';

export type DesignCustomizationModalProps = {
  readonly isCollab: boolean;
  readonly design: Pick<Design, 'id' | 'name' | 'design_number'>;
  readonly updateDesignState?: (des: any) => void;
  readonly closeModal: () => void;
}

export type DesignCustomizationModalState = {
  readonly helpTypes: any[];
  readonly scope?: string;
  readonly phone?: string;
  readonly title?: string;
  readonly newFriendsList: string[];
}

class DesignCustomizationModal extends React.PureComponent<DesignCustomizationModalProps, DesignCustomizationModalState> {
  constructor(props: DesignCustomizationModalProps) {
    super(props)
    this.state = {
      newFriendsList: ['', '', ''],
      helpTypes: [],
    }
    const that = this

    that.state = {
      scope: '',
      phone: '',
      newFriendsList: ['', '', ''],
      helpTypes: [],
      title: `${that.props.isCollab ? '' : 'Copy of '}"${that.props.design.name}" (${that.props.design.design_number}`
    }

    this.addNewMemberEmailInput = this.addNewMemberEmailInput.bind(this)
    this.updateNewMembersEmailList = this.updateNewMembersEmailList.bind(this)
    this.submitFriendInviteForm = this.submitFriendInviteForm.bind(this)
    this.removeNewMemberEmailInput = this.removeNewMemberEmailInput.bind(this)
  }

  submitFriendInviteForm = () => {
    toastr.success(I18n.t('views.checkout.carts.design_review_modal.email_invites_sent'))
  }

  addNewMemberEmailInput() {
    const updatedList = [...this.state.newFriendsList, '']
    this.setState({
      newFriendsList: updatedList,
    })
  }

  removeNewMemberEmailInput(pIndex) {
    const { newFriendsList } = this.state
    if (newFriendsList.length > 1) {
      const nextNewFriendsList = removeArrayElement(newFriendsList, pIndex)
      this.setState({ newFriendsList: nextNewFriendsList });
    } else {
      this.setState({
        newFriendsList: [''],
      })
    }
  }

  updateNewMembersEmailList(pIndex, newValue) {
    const updatedList = [...this.state.newFriendsList]
    updatedList[pIndex] = newValue
    this.setState({ newFriendsList: updatedList })
  }

  updatePhone = e => this.setState({ phone: e.target.value })

  updateScopeField = e => this.setState({ scope: e.target.value })

  handleCheckBoxChange = e => {
    let helpTypes = this.state.helpTypes
    if (e.target.checked) {
      // Only include the element in case it's not already in the array
      if (!helpTypes.includes(e.target.name)) {
        helpTypes.push(e.target.name)
      }
    } else if (helpTypes.includes(e.target.name)) { // Only remove the element in case it exists in the array
      helpTypes = helpTypes.filter(type => {
        return type !== e.target.name
      })
    }
    this.setState({
      helpTypes: helpTypes,
    })
  }

  handleSubmit = () => {
    const that = this
    if (this.state.helpTypes.length <= 0) {
      $('#review_type_error').removeClass('hidden')
      return
    } else {
      $('#review_type_error').addClass('hidden')
    }
    if (isEmpty(this.state.phone)) {
      $('.phone-input').css({ borderColor: 'red' })
      return
    } else {
      $('.phone-input').css({ borderColor: '#ccc' })
    }
    let payload = {
      og_design_id: this.props.design.id,
      types: this.state.helpTypes,
      scope: this.state.scope,
      phone: this.state.phone,
      invite_list: this.state.newFriendsList,
      title: `${this.props.isCollab ? '' : 'Copy of '}"${this.props.design.name}" (${this.props.design.design_number
        }`,
    }

    DesignService.sendDesignCustomization(payload, res => {
      if (undefined == res) {
        toastr.error('Error occured...', '', { timeOut: 10000 })
      } else {
        if (that.props.updateDesignState) {
          that.props.updateDesignState(res.design)
        }
        that.props.closeModal()
        const requestSentText = I18n.t('views.checkout.carts.design_review_modal.request_sent')
        const goToDesignText = I18n.t('views.checkout.carts.design_review_modal.go_to_design')
        toastr.success(
          `${requestSentText}
            <br> ${res.cloned
            ? `<a style="text-decoration:underline;" href="/designs/${res.design.id}">${goToDesignText}</a>.`
            : ''
          }`,
          '',
          { timeOut: 15000 }
        )
      }
    })
  }

  render() {
    const { name, design_number } = this.props.design
    const versionText = I18n.t('views.checkout.carts.design_review_modal.version')
    let designInfoTitle = `${name} (${design_number})`

    if (!this.props.isCollab) {
      const copyOfText = I18n.t('views.checkout.carts.design_review_modal.copy_of')
      designInfoTitle = `${copyOfText} ${designInfoTitle}`
    }

    const headers = {
      designInfo: I18n.t('views.checkout.carts.design_review_modal.headers.design_info'),
      typeOfHelp: I18n.t('views.checkout.carts.design_review_modal.headers.type_of_help'),
      defineYourScope: I18n.t('views.checkout.carts.design_review_modal.headers.define_your_scope'),
      phoneNumber: I18n.t('views.checkout.carts.design_review_modal.headers.phone_number'),
      inviteGuests: I18n.t('views.checkout.carts.design_review_modal.headers.invite_guests'),
    }

    const helpTypes = [
      'general_assistance',
      'cost_optimization',
      'structural_integrity',
      'motion_control',
      'customize',
    ]

    const disclaimerPrefixText = I18n.t('views.checkout.carts.design_review_modal.disclaimer_prefix')
    const termsText = I18n.t('views.common.form.agreement.terms')
    const andText = I18n.t('views.common.form.agreement.and')
    const policyText = I18n.t('views.common.form.agreement.policy')

    return (
      <div id='design-customization-modal'>
        <div className='sub-header'>
          {I18n.t('views.checkout.carts.design_review_modal.modal_description')}
        </div>
        <div className='grid'>
          <div className='header info'>{headers.designInfo}</div>
          <div className='title'>{designInfoTitle}</div>
          <div className='header type required'>
            {headers.typeOfHelp}
            <div id='review_type_error' className='hidden skinny has-error'>
              {I18n.t('views.checkout.carts.design_review_modal.please_select_types')}
            </div>
          </div>
          <div className='options'>
            {
              helpTypes.map(helpType => {
                const helpTypeText = I18n.t(`views.checkout.carts.design_review_modal.help_type.${helpType}`)
                return (
                  <label className='checkbox-label skinny'>
                    <input
                      className='checkbox'
                      type='checkbox'
                      name={helpTypeText}
                      onChange={this.handleCheckBoxChange}
                    />
                    {helpTypeText}
                  </label>
                )
              })
            }
          </div>
          <div className='header'>{headers.defineYourScope}</div>
          <div className='scope'>
            <textarea
              name='scope'
              value={this.state.scope}
              onChange={this.updateScopeField}
              className='form-control scope-input'
              rows={4}
            />
          </div>
          <div className='header phone-title required'>{headers.phoneNumber}</div>
          <div className='phone-input-wrapper'>
            <input
              name='phone'
              type='phone'
              className='form-control phone-input'
              onChange={this.updatePhone}
            />
          </div>

          <div className='header invite-title'>{headers.inviteGuests}</div>
          <div className='invite-input'>
            <ValidatorForm
              id='invite-friends-form'
              className='form'
              onSubmit={this.handleSubmit}
              instantValidate={false}
            >
              {this.state.newFriendsList.map((email, index) => {
                return (
                  <FriendInviteMemberAccess
                    key={index}
                    index={index}
                    email={email}
                    removeNewMemberEmailInput={this.removeNewMemberEmailInput}
                    updateNewMembersEmailList={this.updateNewMembersEmailList}
                    placeholderText={I18n.t('views.checkout.carts.design_review_modal.work_email_address')}
                    required={false}
                  />
                )
              })}
            </ValidatorForm>
            <button onClick={this.addNewMemberEmailInput} className='invite-members-more-button'>
              <span className='invite-members-more-button-icon'></span>
              <span className='invite-members-more-button-text'>
                {I18n.t('views.checkout.carts.design_review_modal.invite_more_guests')}
              </span>
            </button>
          </div>
        </div>
        <div className='container-fluid text-center customization-modal-footer'>
          <div className='actions'>
            <div className='text-center'>
              <button
                className='button button-md button-success'
                form='invite-friends-form'
                id='design-review-submit'
              >
                {I18n.t('views.common.form.buttons.submit')}
              </button>
            </div>
            <div className='privacy'>
              {disclaimerPrefixText + ' '}
              <a href='/terms-of-use' target='_blank' rel='noopener noreferrer'>{termsText}</a>
              {` ${andText} `}
              <a href='/privacy-policy' target='_blank' rel='noopener noreferrer'>{policyText}</a>.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DesignCustomizationModal
